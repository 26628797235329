body {
  margin: 0;
  font-family: Neue Haas Unica !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #141417 !important;
  /* background: -webkit-linear-gradient(
    180deg,
    #0e0d30 0%,
    #0c1014 73.96%,
    #14133d 100%
  ); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body,
html {
  overflow-x: hidden;
}
