--/* .custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,102,203, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
} */

.custom-toggler.navbar-toggler {
  border: 0px;
}

/* .navbar {
  background-color: #363636;
  padding: 30px 70px !important;
} */

.nav-link {
  color: white !important;
  font-size: 15px !important;
}

.nav-item {
  padding: 10px 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.nav-item-invite-display {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  border: 2px solid rgb(255, 255, 255, 0);
  border-radius: 40px;
  padding: 10px 20px !important;
  cursor: pointer;
}

.nav-item-invite {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  border: 2px solid white;
  border-radius: 40px;
  padding: 10px 10px !important;
  cursor: pointer;
}

.header-erro-email h1 {
  font-size: 10px;
  color: white;
  position: relative;
}

.header-nav-item-input-display {
  background-color: #141417;
  border: 0px;
  color: white !important;
  display: block;
}

.header-nav-item-input-display:focus {
  outline: none;
}

.header-nav-item-input {
  display: none;
}
.navbar-toggler {
  color: white;
}
.invite {
  background-color: white;
  width: 150px;
  height: 40px;
  border-radius: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invite span {
  color: black;
  font-size: 15px;
  line-height: 1.8;
}
.connect-div {
  background-color: #363636;
  display: flex;
  justify-content: center;
  padding: 160px 0px 0px 0px;
  text-align: center;
}

.connect-div h1 {
  color: white;
  font-size: 60px;
  font-weight: 800;
  font-style: normal;
}

.connect-div2 {
  background-color: #363636;
  padding: 30px 0px 0px 0px;
}

.connect-div2-row {
  justify-content: center;
}

.connect-div2 h4 {
  color: whitesmoke;
  font-size: 24px;
  font-style: normal;
}

.connect-div2-col {
  padding: 0px !important;
}

.connect-div2-col-invite {
  padding: 0px !important;
  max-width: 145px !important;
}

.connect-input {
  background-color: #363636;
  display: flex;
  justify-content: center;
  padding: 30px 0px 0px 0px;
  height: 42px;
}
.connect-input input {
  background-color: #363636;
  border: solid 1px white;
  border-radius: 5px 0px 0px 5px;
  height: 42px;
  padding: 2.5px 0px 0px 15px;
  color: white;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  line-height: 1.8;
}
.connect-erro-email {
  padding: 40px 120px 120px 0px;
}
.connect-erro-email h1 {
  color: yellow;
  text-align: center;
  font-size: 16px;
}

.connect-input input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #939393;
  opacity: 1; /* Firefox */
}

.connect-input input::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #939393;
}

.connect-input input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: red;
}

.connect-input-div {
  display: flex;
  justify-content: center;
  height: 42px;
  background-color: white;
  border: solid 1px white;
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
  width: 145px;
  align-items: center;
}

.connect-input-div span {
  color: black;
  font-weight: 500;
  font-size: 16px;
}

.content-img {
  background-color: #363636;
  display: flex;
  justify-content: center;
  padding: 0px 0px 100px 0px;
}

.section-div {
  padding: 100px 10px 100px 10px;
  background-color: #f6f6f6;
}
.section2-collaborate p {
  color: #939393;
  font-size: 16px;
  font-weight: 700;
  padding: 20px 0px 0px 0px;
}

.section2-recommand {
  font-size: 48px;
  font-weight: normal;
  font-style: normal;
  padding: 0px 0px;
  color: #363636;
}

.section2-recommand-coverage {
  font-size: 48px;
  font-weight: normal;
  font-style: normal;
  color: #363636;
  bottom: 15px;
}

.section2-ipsum p {
  color: #939393;
  font-size: 24px;
  font-weight: normal;
}
.section-image {
  display: flex;
  justify-content: center;
  padding: 60px 0px 0px 0px;
  background-color: #e8e8e8;
  border-radius: 4px;
  width: 568px;
}
.section-image img {
  width: 400px;
}

.section2-pipeline {
  font-size: 59px;
  font-weight: 500;
  padding: 10px 0px;
  color: #363636;
}

.section2-conenct p {
  color: #939393;
  font-size: 16px;
  font-weight: 700;
  padding: 20px 0px 0px 0px;
}
.section-image-mob {
  display: none;
}
.section-col2 {
  margin: 50px !important;
}
.section-col2-pipe {
  margin: 50px 0px 0px 0px !important;
}

.about-heading {
  display: flex;
  justify-content: center;
  padding: 50px 0px 0px 0px;
}
.about-heading h1 {
  color: white;
  font-weight: normal;
  font-size: 48px;
}
.about-lowhead {
  display: flex;
  justify-content: center;
  padding: 10px 0px 0px 0px;
}
.about-lowhead h1 {
  color: white;
  font-weight: normal;
  font-size: 48px;
}

.multiple-cards {
  padding: 30px 0px 100px 0px;
}

.about-circle {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: inline-block;
}
.about-card-col {
  display: flex;
  justify-content: center;
  background-color: #727272;
  margin: 30px;
  padding: 50px 0px 0px 0px;
  border-radius: 10px;
}
.about-card-col h1 {
  font-size: 16px;
  color: #c4c4c4;
  padding: 5px 0px 0px 0px;
}
.about-card-col h2 {
  font-size: 14px;
  color: #c4c4c4;
}

.about-card-col p {
  font-size: 16px;
  color: #c4c4c4;
  padding: 20px 0px 20px 0px;
  font-weight: 500;
}

.footer {
  background-color: #eeeeee;
  margin: 30px 30px 160px 30px;
  border-radius: 10px;
}
.footer-title {
  margin-top: 177px;
}
.footer-title-click {
  top: 323px;
  z-index: 99;
}
.footer-title-click h1 {
  padding: 0px 0px 50px 60px;
  opacity: 0.9;
  font-size: 46px;
  margin-top: auto;
}

.footer-title h1 {
  padding: 0px 0px 50px 60px;
  opacity: 0.9;
  font-size: 46px;
  margin-top: auto;
}

.footer-row {
  height: 335px;
}

.footer-join-list {
  background: #ffffff;
  display: flex;
  justify-content: center;
  border: 1px solid #cdcdcd;
  width: 271px;
  height: 74px;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0px 0.5px 1px 0.4px rgba(54, 54, 54, 0.3);
  cursor: pointer;
  align-items: center;
}
.footer-join-list h2 {
  font-size: 25px;
  font-weight: 500;
  margin: 20px;
  padding-top: 5px;
}
.footer-join-list-col {
  margin-top: auto;
  padding: 0px 0px 75px 0px;
}

.footer-baar {
  border: 1px solid #e8e8e8 !important;
}
.footer-copyright h3 {
  color: #eeeeee;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  padding: 15px 0px 0px 0px;
}
.footer-bottom {
  display: flex;
  justify-content: space-between;
  padding: 20px 50px 10px 100px;
}
.footer-input-mob {
  display: none;
}
.footer-form {
  display: flex;
  padding: 0px 0px 0px 60px;
}
.footer-form-div {
  width: 90%;
  height: 100px;
  border: 1px solid rgba(95, 117, 134, 1);
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px 10px 20px;
  background: #eeeeee;
  border-radius: 20px;
}
.footer-form-div-disply {
  width: 90%;
  height: 100px;
  border: 1px solid rgba(95, 117, 134, 0);
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px 10px 20px;
  background: #eeeeee;
  border-radius: 20px;
}
.footer-form-div input {
  background: #eeeeee;
  border: 0px;
  width: 75%;
  height: 74px;
  align-items: center;
  border-radius: 4px;
  font-size: 32px;
  font-weight: 500;
  cursor: pointer;
  align-items: center;
}

.footer-form-div-disply input {
  display: none;
}

.footer-join-list-mob {
  display: none !important;
}

.footer-erro-email {
  padding: 20px 0px 0px 0px;
}
.footer-erro-email h1 {
  color: red;
  text-align: center;
  font-size: 26px;
}
.footer-row-mob-join {
  display: none;
}
.footer-erro-email-mob {
  display: none;
}

/* .footer-form-input {
  display: none;
}
.footer-form-input-display {
  display: block;
} */
/*.....Media Query.... */

@media screen and (max-width: 1050px) {
  .section-col2-pipe {
    margin: 50px 0px 0px 50px !important;
  }
}
@media screen and (max-width: 765px) {
  .nav-item-invite {
    display: none;
  }
  .nav-item-invite-display {
    display: none;
  }

  .connect-div h1 {
    padding: 0px 0px 0px 20px;
    text-align: center;
    font-size: 44px;
  }
  .connect-input {
    padding: 0px;
  }
  .connect-input-div span {
    font-weight: 600;
    font-size: 16px;
    line-height: 125.5%;
    padding: 0px;
  }
  .connect-div2 h4 {
    font-weight: normal;
    font-size: 20px;
    line-height: 134.4%;
  }
  .connect-div2-col {
    padding: 30px 0px 0px 0px !important;
  }

  .connect-div2-col-invite {
    padding: 30px 0px 0px 0px !important;
    max-width: 100% !important;
  }
  .connect-input input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    text-align: center;
    font-size: 16px;
  }

  .connect-input input::-ms-input-placeholder {
    text-align: center;
    font-size: 16px;
  }

  .connect-input input::-ms-input-placeholder {
    text-align: center;
    font-size: 16px;
  }
  .connect-input input {
    height: 50px;
    padding: 0px 0px 4px 0px;
  }
  .connect-div2 {
    text-align: center;
  }
  .connect-input-div {
    width: 100%;
    height: 50px;
  }
  .connect-div {
    padding: 30px 0px 0px 0px;
  }
  .connect-div2-row {
    padding: 0px 40px;
  }
  .content-img {
    display: none;
  }
  .about-heading h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 100.1%;
  }
  .connect-erro-email {
    padding: 40px 120px 210px 0px;
  }

  .multiple-cards {
    padding: 0px;
  }
  .about-lowhead h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 130.1%;
  }
  .about-card-col p {
    padding: 20px 18px 20px 0px;
  }
  .footer-title h1 {
    padding: 0px 70px 0px 20px;
    font-size: 36px;
  }
  .footer-title {
    margin-top: 40px;
  }
  .footer-join-list h2 {
    font-size: 22px;
  }
  .section2-recommand {
    font-size: 32px !important;
    font-style: normal;
    font-weight: 600 !important;
    text-align: center;
  }
  .section2-recommand-coverage {
    font-size: 32px !important;
    text-align: center;
    font-style: normal;
    font-weight: 600 !important;
  }
  .section2-collaborate p {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 130.1%;
  }
  .section2-ipsum p {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
  }
  .section-div {
    padding: 10px 10px 100px 10px;
  }
  .section-image {
    width: 92%;
    height: 100%;
    padding: 25px 0px 0px 74px;
  }
  .section-image-desktop {
    display: none;
  }
  .section-image-mob {
    display: block;
  }
}

@media screen and (max-width: 575px) {
  .connect-input input {
    border-radius: 5px 5px 5px 5px;
  }
  .connect-input-div {
    border-radius: 5px 5px 5px 5px;
  }
  .section-image {
    width: 95%;
  }
}

.footer-menu {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.footer-menu a {
  color: white;
  text-decoration: none;
}
.footer-menu a:hover {
  color: white;
  text-decoration: none;
}

.terms-container {
  padding: 40px 50px 20px 50px;
}
.privacy-container {
  padding: 40px 50px 20px 50px;
}

@media only screen and (max-width: 575px) {
	.pricing-row:last-child {
		grid-template-columns: auto max-content max-content max-content !important;
	}
}

@media only screen and (max-width: 767px) {
	.dropdown-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.pricing-row-heading {
		font-size: 18px;
	}
	.pricing-row .text {
		font-size: 14px;
	}
}

@media only screen and (min-width: 768px) {
	.menu-dropdown {
		position: absolute;
		right: 100px;
	}
	.pricing-row-heading {
		font-size: 27px;
	}
	.pricing-row .text {
		font-size: 17px;
	}
}

@media only screen and (max-width: 991px) {
	.pricing-row .btn-primary-color {
		padding: 10px 5px !important;
	}
	.pricing-row .btn-primary-outline-color {
		padding: 10px 5px !important;
	}
	.pricing-row {
		grid-column-gap: 10px;
	}
}

@media only screen and (min-width: 992px) {
	.pricing-row .btn-primary-color {
		padding: 12px 50px 12px 50px !important;
	}
	.pricing-row .btn-primary-outline-color {
		padding: 12px 50px 12px 50px !important;
	}
}

@media only screen and (max-width: 768px) {
	.basic-navbar-nav {
		position: absolute !important;
		top: 60px !important;
		width: 100% !important;
		z-index: 999 !important;
	}
	.navbar-wrapper {
		border-left: 1px solid #735BFF;
		width: 73%;
		background: #1A1A1A;
		height: calc(100vh - 100px);
		padding-top: 10px;
		padding-left: 30px;
	}
	.menu-option {
		font-family: SF Pro Display !important;
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 120%;
		display: flex;
		align-items: center;
		letter-spacing: -0.01em;
		color: #FFFFFF;
		min-height: 40px;
	}
	.dropdown-wrapper {
		display: none !important;
	}
	.solution-sub-options {
		margin-left: 15px;
		margin-top: 10px;
		margin-bottom: 10px;
	}
	.solution-sub-options p {
		margin: 0;
		font-family: SF Pro Display !important;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 120%;
		display: flex;
		align-items: center;
		letter-spacing: -0.01em;
		color: #FFFFFF;
		height: 32px;
	}
	.solution-sub-options a, .solution-sub-options a:active {
		color: #fff;
		text-decoration: none;
	}
	.solution-menu {
		margin-top: 10px;
	}
}

@media only screen and (min-width: 769px) {
	.menu-option {
		font-family: SF Pro Display !important;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		display: flex;
		align-items: center;
		text-align: center;
		color: #FFFFFF;
		margin-left: 25px;
	}
	.solution-sub-options {
		display: none !important;
	}
	.menu:hover, .menu.active {
		border-bottom: 1px solid #FFFFFF;
		cursor: pointer;
	}
}

.menu a, .menu a:active, .menu a:hover {
	color: #fff;
	text-decoration: none;
}

.add-to-chrome-btn {
	border-radius: 46px;
}
.menu-dropdown {
	background: #3F3F3F;
	border: 7px solid #735BFF;
	border-radius: 22px;
	z-index: 9999;
	padding: 20px 10px 25px 30px;
	width: 540px;
	height: 325px;
}
.dropdown-text {
	font-family: SF Pro Display !important;
	font-style: normal;
	font-weight: 500;
	font-size: 29px;
	line-height: 35px;
	display: flex;
	align-items: center;
	color: #FFFFFF;
	margin-top: 5px;
	width: max-content;
}
.dropdown-text a, .dropdown-text a:hover {
	color: #FFFFFF;
	text-decoration: none;
}
.dropdown-text:hover, .dropdown-text.active {
	border-bottom: 1px solid #FFFFFF;
	cursor: pointer;
}
.dropdown-option img {
	height: 60px;
	width: 60px;
}
.dropdown-option {
	display: grid;
	grid-template-columns: 20% 80%;
	align-items: center;
	margin-bottom: 40px;
}
.pricing-label {
	font-family: SF Pro Display !important;
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
	line-height: 120%;
	display: flex;
	align-items: center;
	letter-spacing: -0.01em;
	color: #FFFFFF;
}
.pricing-content {
	font-family: SF Pro Display !important;
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 120%;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: -0.01em;
	color: #EEEEEE;
	margin: 0;
}
.pricing-content-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 40px;
	margin-bottom: 70px;
}
.pricing-row-heading {
	font-family: SF Pro Display !important;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
	letter-spacing: -0.01em;
	color: #FFFFFF;
}
.pricing-row {
	display: grid;
	grid-template-columns: 32% 24% 24% 20%;
	align-items: center;
	margin-bottom: 12px;
}
.pricing-empty-row {
	padding: 20px 0;
}
.pricing-row .text {
	font-family: SF Pro Display !important;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	display: flex;
	align-items: center;
	letter-spacing: -0.01em;
	color: #FFFFFF;
}
.pricing-row div:nth-child(n+2) {
	text-align: center;
}
.btn-primary-outline-color {
	padding: 12px 50px 12px 50px;
	background: white;
	color: #735bff;
	border-radius: 4px;
	border-style: none;
}
.main-footer {
	margin-top: auto;
}
.Toastify {
	display: none !important;
}
.blog-link:hover, .blog-title {
	color: #EAEFF2;
}
.blog-content {
	color: #EAEFF2;
	padding-left: 40px;
}
.terms-container, .privacy-container {
	color: white;
}
.blog-list {
	padding-left: 40px;
	color: #EAEFF2;
	list-style: none;
}
.blogs-container .card {
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
	transition: 0.3s;
	width: 40%;
	border-radius: 5px;
	color: #333;
	padding: 15px;
	width: 100%;
}
.blogs-container .card:hover {
	box-shadow: 0 8px 16px 0 #bbc4c2;
	cursor: pointer;
	transform: scale(1.01);
}
.contact-us-iframe {
	height: 75vh;
	width: 100%;
	border: none;
}