.title-section {
  display: flex;
  justify-content: center;
  padding: 80px 0px 0px 0px;
}

.title-section h1 {
  color: white;
  padding: 0px 0px 20px 0px;
  font-size: 48px;
  text-align: center;
}

.title-section p {
  color: white;
  text-align: center;
  margin: 0px;
  font-weight: 500;
  font-size: 18px;
  opacity: 0.8;
  font-size: 24px;
}
